import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { homeKeys } from "src/data/homes/queries/homeQueryCache"
import {
  IFetchHomes,
  IHomeFilterBody,
} from "src/data/homes/types/homeQueryTypes"
import {
  IProviderHomeResponse,
  IResponseServiceCalloutEstimates,
  IResponseServiceErrorResponse,
  IResponseServicePatch,
  IResponseServicePatchResponse,
  IResponseServiceProviderResponse,
  IResponseServiceProvidersResponse,
  IResponseServiceToggleEstimateResponse,
  ResponseServiceToggleAction,
} from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { fetchHomes } from "src/data/organizations/queries/homeQueries"
import {
  orgsKeys,
  useOrgQueryCache,
} from "src/data/organizations/queries/organizationQueryCache"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

const ORGS_API = `${API_DEFAULT}/organizations`

export function useFetchResponseServiceToggleEstimate({
  homeId,
  toggleAction,
  provider,
  options,
}: {
  homeId: string
  toggleAction: ResponseServiceToggleAction
  provider: string
  options?: UseQueryOptions<
    IResponseServiceToggleEstimateResponse,
    AxiosError<IResponseServiceErrorResponse>,
    IResponseServiceToggleEstimateResponse,
    ReturnType<typeof homeKeys.responseServiceToggleEstimate>
  >
}) {
  async function fetchResponseServiceToggleEstimate() {
    const response =
      await minutApiHttpClient.get<IResponseServiceToggleEstimateResponse>(
        `${API_DEFAULT}/subscriptions/estimate/response_service/${homeId}/${toggleAction}?provider=${provider}`
      )
    return response.data
  }

  return useQuery(
    homeKeys.responseServiceToggleEstimate(homeId, toggleAction),
    fetchResponseServiceToggleEstimate,
    {
      keepPreviousData: true,
      staleTime: 60 * 1000, //60s stale time to prevent frequent refetching
      ...options,
    }
  )
}

interface IPatchResponseService {
  homeId: string
  orgId: string
  body: IResponseServicePatch
}

export function usePatchResponseService() {
  const cache = useOrgQueryCache()
  const queryClient = useQueryClient()

  async function patchReponseService({
    homeId,
    orgId,
    body,
  }: IPatchResponseService) {
    const response =
      await minutApiHttpClient.patch<IResponseServicePatchResponse>(
        `${ORGS_API}/${orgId}/homes/${homeId}/response_service`,
        body
      )
    return response.data
  }

  return useMutation<
    IResponseServicePatchResponse,
    AxiosError<IResponseServiceErrorResponse>,
    IPatchResponseService
  >(patchReponseService, {
    onSuccess: (data, { orgId, homeId }) => {
      cache.updateCachedHomeDetail(orgId, homeId, (home) => ({
        ...home,
        response_service: { ...home.response_service, ...data },
      }))
      // N.B: This manual cache update doesn't seem to update inactive home list
      // queries; if that's necessary, handle them in the mutation onSuccess
      // handler at the call site.
      cache.updateCachedHomeList(orgId, (homes) =>
        homes.map((home) => {
          if (home.home_id === homeId) {
            return {
              ...home,
              response_service: { ...home.response_service, ...data },
            }
          }
          return home
        })
      )
      return queryClient.invalidateQueries(orgsKeys.responseServiceHomes(orgId))
    },
  })
}

export function useFetchResponseServiceAddons(props?: {
  options?: UseQueryOptions<
    IResponseServiceCalloutEstimates,
    AxiosError,
    IResponseServiceCalloutEstimates,
    ReturnType<typeof homeKeys.responseServiceCalloutEstimates>
  >
}) {
  const { options } = props || {}
  async function fetchResponseServiceCalloutEstimates() {
    const response =
      await minutApiHttpClient.get<IResponseServiceCalloutEstimates>(
        `${API_DEFAULT}/subscriptions/estimate/response_service/addons`
      )
    return response.data
  }

  return useQuery(
    homeKeys.responseServiceCalloutEstimates(),
    fetchResponseServiceCalloutEstimates,
    {
      keepPreviousData: true,
      staleTime: 15 * 60 * 1000, //15 min stale time since it is a static endpoint
      ...options,
    }
  )
}

export function useFetchProviderHomes({
  orgId,
  homeIds,
  filter,
  options,
}: {
  orgId: string
  homeIds: string[]
  filter?: { address?: string }
  options?: UseQueryOptions<
    IProviderHomeResponse,
    AxiosError,
    IProviderHomeResponse,
    ReturnType<typeof orgsKeys.providerHomes>
  >
}) {
  async function fetchProviderHomes() {
    const response = await minutApiHttpClient.get<IProviderHomeResponse>(
      `${API_DEFAULT}/organizations/${orgId}/provider/homes`,
      {
        params: {
          ids: homeIds,
        },
      }
    )
    return response.data
  }

  return useQuery(
    orgsKeys.providerHomes(orgId, homeIds, filter),
    fetchProviderHomes,
    options
  )
}

export function useFetchResponseServiceProvider({
  providerId,
  options,
}: {
  providerId: string
  options?: UseQueryOptions<
    IResponseServiceProviderResponse,
    AxiosError,
    IResponseServiceProviderResponse,
    ReturnType<typeof homeKeys.responseServiceProvider>
  >
}) {
  async function fetchResponseServiceProvider() {
    const response =
      await minutApiHttpClient.get<IResponseServiceProviderResponse>(
        `${API_DEFAULT}/response_service/providers/${providerId}`
      )
    return response.data
  }

  return useQuery(
    homeKeys.responseServiceProvider(providerId),
    fetchResponseServiceProvider,
    options
  )
}

export function useFetchResponseServiceProviders(props?: {
  options?: UseQueryOptions<
    IResponseServiceProvidersResponse,
    AxiosError,
    IResponseServiceProvidersResponse,
    ReturnType<typeof homeKeys.responseServiceProviders>
  >
}) {
  async function fetchResponseServiceProvider() {
    const response =
      await minutApiHttpClient.get<IResponseServiceProvidersResponse>(
        `${API_DEFAULT}/response_service/providers`
      )
    return response.data
  }

  return useQuery(
    homeKeys.responseServiceProviders(),
    fetchResponseServiceProvider,
    props?.options
  )
}

export function useFetchResponseServiceHomes<DataType = IFetchHomes>({
  filters,
  options,
}: {
  filters?: IHomeFilterBody
  options?: UseQueryOptions<
    IFetchHomes,
    AxiosError,
    DataType,
    ReturnType<typeof orgsKeys.responseServiceHomes>
  >
}) {
  const { orgId } = useOrganization()
  return useQuery({
    queryKey: orgsKeys.responseServiceHomes(orgId, filters),
    queryFn: () =>
      fetchHomes({
        orgId,
        filters: { ...filters, response_service_active: true },
      }),
    ...options,
  })
}

export function useFetchHasResponseServiceActive() {
  const fetchActiveHomes = useFetchResponseServiceHomes({
    filters: {
      limit: 1,
      response_service_active: true,
    },
    options: {
      select(data) {
        return data.paging.total_count > 0
      },
    },
  })
  return fetchActiveHomes
}
